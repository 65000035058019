import classnames from 'classnames';
import React from 'react';
import styles from './ArticleCard.module.scss';
import { ResourceArticle } from '../intrfaces';

interface Props {
  article: ResourceArticle;
}

const ArticleCard: React.FC<Props> = ({
  article: { title, image1, image2, image3, text },
}: Props) => {
  return (
    <div className={styles.ArticleCard}>
      <div className={styles.componentBox}>
        <div className={styles.contentBox}>
          <h1 className={styles.title}>{title}</h1>

          <p className={styles.content} dangerouslySetInnerHTML={{ __html: `${text}` }}></p>
          <section className={classnames(styles.imgSection)}>
            <div className={styles.imgWrapper} style={image1 === null ? { display: 'none' } : {}}>
              {image1 && <img className={styles.image} src={image1.url} alt="article img" />}
              {image1 && image1.caption !== null && (
                <p className={styles.caption}>{image1.caption}</p>
              )}
            </div>
            <div className={styles.imgWrapper} style={image2 === null ? { display: 'none' } : {}}>
              {image2 && <img className={styles.image} src={image2.url} alt="article img" />}
              {image2 && image2.caption !== null && (
                <p className={styles.caption}>{image2.caption}</p>
              )}
            </div>
            <div className={styles.imgWrapper} style={image3 === null ? { display: 'none' } : {}}>
              {image3 && <img className={styles.image} src={image3.url} alt="article img" />}
              {image3 && image3.caption !== null && (
                <p className={styles.caption}>{image3.caption}</p>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
