import React, { useState } from 'react';
import Highlights from '../components/shared/highlights/Highlights';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import styles from './resources.module.scss';
import LinksBlock from '../components/resources/links-block/LinksBlock';
import { graphql } from 'gatsby';
import { ResourceArticle } from '../components/resources/intrfaces';

export const query = graphql`
  query {
    strapi {
      resourcesPage {
        title
        subtitle
      }
      resourcesLinks(sort: "sortIndex:asc") {
        head
        resourceArticles: resource_articles(sort: "sortIndex:asc") {
          text
          title
          image1 {
            url
            caption
          }
          image2 {
            url
            caption
          }
          image3 {
            url
            caption
          }
        }
      }
    }
  }
`;

interface APIResp {
  strapi: {
    resourcesPage: {
      title: string;
      subtitle: string;
      resources_article_section: string;
    };
    resourcesLinks: Array<{
      head: string;
      resourceArticles: Array<ResourceArticle>;
    }>;
  };
}

interface Props {
  data: APIResp;
}

const ResourcesPage: React.FC<Props> = ({ data: { strapi: data } }: Props) => {
  return (
    <Layout>
      <SEO title={data.resourcesPage.title} />
      <section className={styles.wrapper}>
        <section className={styles.highlightContainer}>
          <Highlights
            subtitleWidth="30rem"
            title={data.resourcesPage.title}
            subtitle={data.resourcesPage.subtitle}
            description={''}
          />
        </section>
      </section>
      <section className={styles.linksSection}>
        {data.resourcesLinks.map((b, i) => (
          <LinksBlock title={b.head} articles={b.resourceArticles} key={`links-block-${i}`} />
        ))}
      </section>
    </Layout>
  );
};

export default ResourcesPage;
