import React from 'react';
import styles from './SectionLink.module.scss';
import { ResourceArticle } from '../intrfaces';

interface Props {
  index: string;
  article: ResourceArticle;
  isSelected: boolean;
  onClick: () => void;
}

const SectionLink: React.FC<Props> = ({
  index,
  article: { title },
  onClick,
  isSelected,
}: Props) => {
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.contentBox}>
        <p className={styles.number}>{index}</p>
        <p className={styles.link + ' ' + (isSelected ? styles.selected : '')}>{title}</p>
      </div>
    </div>
  );
};

export default SectionLink;
