import React, { useState } from 'react';
import styles from './LinksBlock.module.scss';
import SectionLink from '../section-link/SectionLink';
import useViewport from '../../../hooks/useViewport';
import classnames from 'classnames';
import { ResourceArticle } from '../intrfaces';
import ArticleCard from '../article-card/ArticleCard';

interface Props {
  title: string;
  articles: Array<ResourceArticle>;
}

const LinksBlock: React.FC<Props> = ({ title, articles }: Props) => {
  const viewport = useViewport();
  const links1: Array<{ index: string; article: ResourceArticle }> = [];
  const links2: Array<{ index: string; article: ResourceArticle }> = [];

  const [selectedArticle, setArticle] = useState<ResourceArticle | null>(null);

  const switchArticle = (newArticle: ResourceArticle) => {
    if (newArticle === selectedArticle) {
      setArticle(null);
    } else {
      setArticle(newArticle);
    }
  };

  if (!viewport.isMobile && !viewport.isTablet)
    articles.forEach((article, i) =>
      (i + 1) % 2 != 0
        ? links1.push({ article, index: ('0' + (i + 1)).slice(-2) })
        : links2.push({ article, index: ('0' + (i + 1)).slice(-2) }),
    );
  return (
    <article className={styles.wrapper}>
      <div className={styles.contentBox}>
        <h3 className={styles.title}>{title}</h3>

        <div className={classnames(styles.linksContainer, 'hide-on-tablet')}>
          <div className={styles.linksBox}>
            {links1.map((l, i) => (
              <SectionLink
                isSelected={selectedArticle === l.article}
                onClick={() => switchArticle(l.article)}
                {...l}
                key={`resource-link-${i}`}
              />
            ))}
          </div>
          <div className={styles.linksBox}>
            {links2.map((l, i) => (
              <SectionLink
                isSelected={selectedArticle === l.article}
                onClick={() => switchArticle(l.article)}
                {...l}
                key={`resource-link-${i}`}
              />
            ))}
          </div>
        </div>
        <div className={classnames(styles.linksContainer, 'hide-on-desktop')}>
          {articles.map((article, i) => (
            <SectionLink
              isSelected={selectedArticle === article}
              onClick={() => switchArticle(article)}
              article={article}
              index={('0' + (i + 1)).slice(-2)}
              key={`resource-link-${i}`}
            />
          ))}
        </div>

        {selectedArticle && articles.find((a) => a === selectedArticle) && (
          <ArticleCard article={selectedArticle} />
        )}

        <div className={styles.delimiter}></div>
      </div>
    </article>
  );
};

export default LinksBlock;
